define('messenger-webclient/controllers/edit/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    editController: Ember.inject.controller('index'),
    model: Ember.computed.alias('editController.model'),
    emailIntegrations: Ember.computed.alias('editController.emailIntegrations'),
    smsIntegrations: Ember.computed.alias('editController.smsIntegrations'),
    smtpServices: Ember.computed.alias('editController.smtpServices'),

    isEditing: true,

    fullContent: Ember.computed('model.from', 'model.to', 'model.cc', 'model.bcc', 'model.subject', 'model.body', 'model.callback', function () {
      var m = this.get("model");
      return [m.get("from"), m.get("to"), m.get("cc"), m.get("bcc"), m.get("subject"), m.get("body"), m.get("callback")].join(" ");
    }),

    actions: {
      toggleCC: function toggleCC() {
        this.toggleProperty('isShowingCC');
        return false;
      },
      toggleBCC: function toggleBCC() {
        this.toggleProperty('isShowingBCC');
        return false;
      },
      update: function update(btn) {
        this.set('loading', true);
        this.send('loading');
        var $btn = btn.attr('disabled', 'disabled');
        var that = this;

        var obj = this.get("model").toJSON();
        obj.id = obj.parentId;

        var newModel = this.get('store').createRecord("hook", obj);
        newModel.save().then(function (record) {
          that.get('store').unloadRecord(record);
          that.toggleProperty('reload');
        }, function () {
          //that.send('error', response);
        }).finally(function () {
          $btn.removeAttr('disabled');
          that.send('finished');
          that.set('loading', false);
        });
        return false;
      }
    }

  });
});