define("messenger-webclient/locales/en/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    // "some.translation.key": "Text for some.translation.key",
    //
    // "a": {
    //   "nested": {
    //     "key": "Text for a.nested.key"
    //   }
    // },
    //
    // "key.with.interpolation": "Text with {{anInterpolation}}"

    headers: {
      integration: "Integration"
    },

    components: {
      "copy-box": {
        "copy": "Copy",
        "copied": "Copied"
      }
    },

    forms: {
      button: {
        "create-webhook": "create my webhook",
        "update-webhook": "update",
        "create-webhook-loading": "sending...",
        "copy": "Copy",
        "copied": "Copied",
        "new": "create new",
        "sending": "sending...",
        "new-environment": "Create development environment",
        "next": "Next",
        "back": "< Back"
      },
      new: {
        "configuration-settings": "Settings",
        "step-1-title-email": "Email Service",
        "step-1-subtitle-email": "Select and configure your transactional email service",
        "step-1-title-sms": "SMS Service",
        "step-1-subtitle-sms": "Select and configure your transactional sms service",
        "step-2-title": "Template",
        "step-2-subtitle": "Configure your template and create your webhook",
        "step-3-title": "Congratulations!",
        "step-3-subtitle": "Webhook created successfully"
      },
      hook: {
        webhook: "Webhook",
        "webhook-help": "Use this url to send a POST request with json payload. Please don't forget to set content-type header to application/json). Examples <a href=\"https://github.com/haboob-app/haboob-demos\" target=\"_blank\">here</a>",
        edit: "Edit Url",
        "edit-help": "Use this url to edit your template. Please keep it in a safe place or you will have to create a new webhook."
      },
      history: {
        data: "Data",
        response: "Response",
        date: "Date",
        "processing-date": "Processing date",
        environment: "Environment",
        sent: "Sent",
        empty: "No records found"
      },
      email: {
        from: "From",
        "from-help": "Must be the same one configured in your transactional email provider",
        to: "To",
        "to-help": "",
        cc: "Cc",
        "cc-help": "",
        bcc: "Bcc",
        "bcc-help": "",
        subject: "Subject",
        "subject-help": "",
        "callback": "Callback (optional)",
        "callback-help": "We will send you the response when the request is processed",
        body: "Body",
        "body-help": ""
      },
      sms: {
        from: "From",
        "from-help": "Must be the same phone configured in your transactional sms provider or your sender id",
        to: "To",
        "to-help": "",
        "callback": "Callback (optional)",
        "callback-help": "We will send you the response when the request is processed",
        body: "Body",
        "body-help": ""
      },
      integrations: {
        "elastic-email": {
          apiKey: "Api key",
          "apiKey-help": "Find your API Key <a href=\"https://elasticemail.com/account/#/settings/apiconfiguration\" target=\"_blank\"><u>here</u></a>",
          isTransactional: "Is transactional?"
        },
        "mailgun": {
          apiKey: "Api key",
          "apiKey-help": "",
          domain: "Domain",
          "domain-help": "Find your API Key and Domain <a href=\"https://app.mailgun.com/app/domains\" target=\"_blank\"><u>here</u></a>"
        },
        "sendgrid": {
          apiKey: "Api key",
          "apiKey-help": "Find your API Key <a href=\"https://app.sendgrid.com/settings/api_keys\" target=\"_blank\"><u>here</u></a>"
        },
        "aws-ses": {
          "access-key-id": "Access Key Id",
          "access-key-id-help": "",
          "secret-access-key": "Secret Access Key",
          "secret-access-key-help": "",
          "region": "Region",
          "region-help": "Find how to control access to Amazon SES <a href=\"http://docs.aws.amazon.com/ses/latest/DeveloperGuide/control-user-access.html\" target=\"_blank\"><u>here</u></a>"
        },
        "aws-sns": {
          "access-key-id": "Access Key Id",
          "access-key-id-help": "",
          "secret-access-key": "Secret Access Key",
          "secret-access-key-help": "",
          "region": "Region",
          "region-help": "",
          "isTransactional": "Is transactional?",
          "isTransactional-help": "Find how to send SMS with Amazon SNS <a href=\"http://docs.aws.amazon.com/sns/latest/dg/SMSMessages.html\" target=\"_blank\"><u>here</u></a>"
        },
        "twilio": {
          "accountSid": "Account SID",
          "accountSid-help": "",
          "authToken": "Auth Token",
          "authToken-help": "Find your Account SID and Auth Token <a href=\"https://www.twilio.com/console\" target=\"_blank\"><u>here</u></a>"
        },
        "wk-smtp": {
          "username": "Username",
          "username-help": "",
          "password": "Password",
          "password-help": "",
          "service": "Service",
          "service-help": "We DON'T recommend to use this service. We store plain text, use it at your own risk"
        },
        "test": {
          "info": "Just for testing purpose, instead of delivering the message it processes input data and save a log with the generated data. You can edit the service provider later."
        }
      }
    },
    help: {
      "how-it-works": {
        "title": "Transactional Email/SMS For Developers",
        "subtitle": "No accounts, no custom substitution/merge tags, just a post request with json payload",
        "step-1-title": "1. Email/SMS Service",
        "step-1-subtitle": "Select and configure your transactional email/sms service",
        "step-2-title": "2. Template",
        "step-2-subtitle": "Configure your email/sms template using <a href=\"http://handlebarsjs.com/expressions.html\" target=\"_blank\">handlebars expressions</a>",
        "step-3-title": "3. Webhook",
        "step-3-subtitle": "We give you an edit url and a webhook url to trigger your email/sms",
        "step-4-title": "4. Use It",
        "step-4-subtitle": "Use postman, curl or <a href=\"https://github.com/haboob-app/haboob-demos\" target=\"_blank\">your preferred language</a> to make a post request to your webhook with the json payload that you want",
        "get-started-email": "Get started with EMAIL",
        "get-started-sms": "Get started with SMS"
      },
      payload: {
        "title": "Payload",
        "content": "Please send the next payload to the given webhook:",
        "content-type": "Don't forget to set the Content-Type header as application/json. Examples <a href=\"https://github.com/haboob-app/haboob-demos\" target=\"_blank\">here</a>",
        "no-content": "You can use <a href=\"http://handlebarsjs.com/expressions.html\" target=\"_blank\">handlebars expressions</a> in any of the template fields. Eg:",
        "no-content-email-code": "Hi &#123;&#123;user.name}},<br>please click &#x3C;a href=&#x22;&#123;&#123;confirmUrl}}&#x22;&#x3E;here&#x3C;/a&#x3E; to confirm your account.<br>Regards",
        "no-content-sms-code": "Hi &#123;&#123;user.name}},<br>your verification code is &#123;&#123;code}}.<br>Regards"
      }
    }
  };
});