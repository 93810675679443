define('messenger-webclient/routes/application', ['exports', 'messenger-webclient/mixins/loading-slider'], function (exports, _loadingSlider) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_loadingSlider.default, {
    i18n: Ember.inject.service(),

    afterModel: function afterModel() {
      this.set('i18n.locale', this.calculateLocale());
    },

    calculateLocale: function calculateLocale() {
      // whatever you do to pick a locale for the user:
      var locale = navigator.languages && navigator.languages[0] || navigator.language || navigator.userLanguage || 'en';
      return this.get('i18n.locales').indexOf(locale) >= 0 ? locale : 'en';
    }
  });
});