define('messenger-webclient/models/hook', ['exports', 'ember-data', 'npm:url-join', 'messenger-webclient/config/environment'], function (exports, _emberData, _npmUrlJoin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    parentId: _emberData.default.attr('string'),
    editKey: _emberData.default.attr('string'),
    env: _emberData.default.attr('string'),
    from: _emberData.default.attr('string'),
    to: _emberData.default.attr('string'),
    cc: _emberData.default.attr('string'),
    bcc: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    callback: _emberData.default.attr('string'),
    integration: _emberData.default.attr(),
    readOnly: _emberData.default.attr('boolean', { defaultValue: false }),
    updatedAt: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('number'),

    webHook: computed('id', 'env', function () {
      var id = this.get("parentId") || this.get("id");
      var path = "";
      if (id) {
        path = (0, _npmUrlJoin.default)(_environment.default.APP.apiHost, _environment.default.APP.apiNamespace, '/hooks/' + id + '/send/' + this.get("env"));
      }
      return path;
    }),

    editUrl: computed('id', 'editKey', function () {
      var id = this.get("parentId") || this.get("id");
      var path = "";
      if (id) {
        path = (0, _npmUrlJoin.default)(_environment.default.APP.host, '/' + id + '/edit/' + this.get("editKey"));
      }
      return path;
    })

  });
});