define('messenger-webclient/models/log', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    hookId: _emberData.default.attr('string'),
    timestamp: _emberData.default.attr('string'),
    env: _emberData.default.attr('string'),
    dataToParse: _emberData.default.attr(),
    integration: _emberData.default.attr(),
    updatedAt: _emberData.default.attr('unix-date'),
    createdAt: _emberData.default.attr('unix-date'),
    dataToParseJson: Ember.computed('dataToParse', function () {
      return JSON.stringify(this.get("dataToParse"), null, 2);
    }),
    integrationJson: Ember.computed('integration', function () {
      return JSON.stringify(this.get("integration"), null, 2);
    }),
    sent: Ember.computed('integration', function () {
      return this.get("integration.sent") || "false";
    })
  });
});