define('messenger-webclient/routes/new/step-2', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    controllerName: "new",
    model: function model() {
      return this.modelFor('new');
    },
    afterModel: function afterModel(model) {
      if (!model.get('integration.type')) {
        this.transitionTo('index');
      }
    }
  });
});