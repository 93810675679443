define("messenger-webclient/routes/edit", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    controllerName: "index",

    queryParams: {
      reload: {
        refreshModel: true
      },
      env: {
        refreshModel: true
      }
    },

    model: function model(params) {
      params.env = params.env || "production";
      this.set('params', params);
      return this.get('store').createRecord('hook', {
        body: '',
        integration: { type: 'test' }
      });
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      var params = this.get("params");
      var that = this;
      controller.set('loading', true);
      this.get("store").adapterFor('application').getRequest("/hooks/" + params.hookId + "/edit/" + params.editKey, {}).then(function (response) {
        if (response.hooks.length) {
          response.hooks.map(function (h) {
            h.parentId = h.id;
            h.id += "-" + h.env;
          });
          that.get("store").pushPayload("hook", response);
          var envs = that.get("store").peekAll('hook').filterBy('parentId', params.hookId).sortBy("createdAt");
          controller.setProperties({
            'environments': envs
          });
          model = envs.findBy("env", params.env);
          if (model) {
            controller.setProperties({
              'model': model,
              'loading': false
            });
          } else {
            controller.set("env", "production");
          }
        } else {
          that.transitionTo('index');
        }
      }).catch(function () {
        that.transitionTo('index');
      });
    },


    actions: {
      willTransition: function willTransition() {
        this.send('loading');
      },
      refresh: function refresh() {
        this.refresh();
      }
    }

  });
});