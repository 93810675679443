define('messenger-webclient/controllers/edit/history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      selectRow: function selectRow(log) {
        this.set('selectedRow', log);
        return false;
      }
    }

  });
});