define("messenger-webclient/controllers/index", ["exports", "messenger-webclient/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ["env", "reload"],
    env: "",
    reload: false,

    emailIntegrations: _environment.default.APP.emailServices,
    smsIntegrations: _environment.default.APP.smsServices,
    smtpServices: _environment.default.APP.smtpServices,

    envChanged: Ember.observer("env", "environments", function () {
      if (this.get("environments")) {
        var env = this.get("environments").findBy("env", this.get("env"));
        if (env) {
          this.set("model", env);
        } else {
          this.set("env", "");
        }
      }
    }),

    isTransactionalChanged: Ember.observer("model", "model.integration.isTransactional", function () {
      var model = this.get("model");
      if (model && model.get("integration.isTransactional")) {
        var isTransactional = model.get("integration.isTransactional") + "" === "true";
        model.set("integration.isTransactional", isTransactional);
      }
    }),

    fullContent: Ember.computed('model.from', 'model.to', 'model.cc', 'model.bcc', 'model.subject', 'model.body', 'model.callback', function () {
      var m = this.get("model");
      return [m.get("from"), m.get("to"), m.get("cc"), m.get("bcc"), m.get("subject"), m.get("body"), m.get("callback")].join(" ");
    }),

    actions: {
      toggleCC: function toggleCC() {
        this.toggleProperty('isShowingCC');
        return false;
      },
      toggleBCC: function toggleBCC() {
        this.toggleProperty('isShowingBCC');
        return false;
      },
      create: function create(btn) {
        this.send('loading');
        this.set('loading', true);
        var $btn = btn.attr('disabled', 'disabled');
        var model = this.get('model');
        var that = this;

        model.save().then(function (obj) {
          that.set('model', obj);
        }, function () {
          //that.send('error', response);
        }).finally(function () {
          $btn.removeAttr('disabled');
          that.send('finished');
          that.set('loading', false);
        });
        return false;
      },
      newEnvironment: function newEnvironment(env) {
        var obj = this.get("model").toJSON();
        obj.env = env || "development";
        obj.id = "";

        var newModel = this.get('store').createRecord("hook", obj);
        var that = this;
        newModel.save().then(function (record) {
          that.get('store').unloadRecord(record);
          that.transitionToRoute('edit.index', obj.parentId, obj.editKey, { queryParams: { env: obj.env } });
          //that.send('showMessage', 'Well done! program application submitted');
        }, function () {
          //that.send('error', response);
        }).finally(function () {
          //$btn.button('reset');
        });
        return false;
      },
      setEnvironment: function setEnvironment(env) {
        this.set("env", env);
      }
    }

  });
});