define('messenger-webclient/routes/edit/history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [];
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      var parentModel = this.paramsFor('edit');
      var that = this;

      controller.set('loading', true);

      return this.get("store").adapterFor('application').getRequest('/hooks/' + parentModel.hookId + '/edit/' + parentModel.editKey + '/logs', {}).then(function (response) {
        if (response && response.logs && response.logs.length) {
          response.logs.map(function (h) {
            h.id = h.hookId + "-" + h.timestamp;
          });
          that.get("store").pushPayload("log", response);
          var m = that.get("store").peekAll('log').filterBy('hookId', parentModel.hookId);
          controller.setProperties({
            "model": m,
            "selectedRow": m.get("firstObject"),
            'loading': false
          });
        } else {
          controller.setProperties({
            "model": [],
            "selectedRow": null,
            'loading': false
          });
        }
      }).catch(function () {
        that.transitionTo('index');
      });
    }
  });
});