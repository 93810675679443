define('messenger-webclient/routes/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      reload: {
        refreshModel: true
      }
    },

    model: function model(params) {
      return this.get('store').createRecord('hook', {
        type: params.type || 'email',
        integration: { type: params.provider || '', isTransactional: true }
      });
    }
  });
});