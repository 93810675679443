define('messenger-webclient/routes/edit/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.get('store').createRecord('hook', {
        body: '',
        integration: { type: 'test' }
      });
    }
  });
});