define('messenger-webclient/components/parsley-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    i18n: Ember.inject.service(),

    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get('reset') != this.get('_reset')) {
        this.resetForm();
        this.set('_reset', this.get('reset'));
      }
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      window.Parsley.setLocale(this.get('i18n.locale'));
      window.Parsley.options = Ember.merge(window.Parsley.options, {
        errorClass: 'is-invalid',
        successClass: 'is-valid',
        errorsWrapper: '<div class="invalid-feedback">',
        errorTemplate: '<span></span>'
      });

      window.Parsley.addValidator('gted', function (value, requirement) {
        return window.moment(value).unix() >= window.moment(requirement).unix();
      }, 32).addMessage('en', 'gted', 'This value should be greater or equal to %s').addMessage('es', 'gted', 'Este valor debe ser mayor o igual a %s');

      Ember.run.scheduleOnce('afterRender', this, function () {
        self.$(this.element).find('*:input[type!=hidden]:not([disabled]):first').focus();
      });
    },
    resetForm: function resetForm() {
      var form = this.$(this.element).find('form');
      var parsleyForm = form.parsley();
      parsleyForm.reset();
      form.trigger("reset");
      Ember.run.later(function () {
        form.find('input[type!=hidden]:not([disabled]):first').focus();
      }, 300);
    },


    actions: {
      submit: function submit() {
        var form = this.$(this.element).find('form');
        var parsleyForm = form.parsley();
        var validatedForm = parsleyForm.validate();

        if (validatedForm) {
          this.sendAction('action', this.$(this.element).find('form :submit'));
        }
      },
      reset: function reset() {
        this.resetForm();
      }
    }
  });
});