define('messenger-webclient/components/copy-box', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['card'],
    actions: {
      didCopy: function didCopy() {
        var that = this;
        this.set('isCopied', true);
        Ember.run.later(function () {
          that.set('isCopied', false);
        }, 800);
        return false;
      }
    }
  });
});