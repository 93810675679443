define('messenger-webclient/adapters/application', ['exports', 'ember-data', 'messenger-webclient/config/environment', 'npm:url-join'], function (exports, _emberData, _environment, _npmUrlJoin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend({
        host: _environment.default.APP.apiHost,
        namespace: _environment.default.APP.apiNamespace,

        getRequest: function getRequest(path, data) {
            var url = (0, _npmUrlJoin.default)(_environment.default.APP.apiHost, _environment.default.APP.apiNamespace, path);
            return this.ajax(url, 'GET', { data: data });
        },
        sendData: function sendData(method, path, data, customRequestArguments) {
            var url = (0, _npmUrlJoin.default)(_environment.default.APP.apiHost, _environment.default.APP.apiNamespace, path);
            var requestArguments = Ember.$.extend({ data: data }, customRequestArguments);

            return this.ajax(url, method, requestArguments);
        },
        postRequest: function postRequest(path, data) {
            var customRequestArguments = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            return this.sendData('POST', path, data, customRequestArguments);
        },
        putRequest: function putRequest(path, data) {
            var customRequestArguments = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            return this.sendData('PUT', path, data, customRequestArguments);
        }
    });
});