define("messenger-webclient/controllers/new", ["exports", "messenger-webclient/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    emailIntegrations: _environment.default.APP.emailServices,
    smsIntegrations: _environment.default.APP.smsServices,
    smtpServices: _environment.default.APP.smtpServices,

    queryParams: ["provider", "reload", "type"],
    provider: "",
    reload: "",
    type: "email",

    fullContent: Ember.computed('model.from', 'model.to', 'model.cc', 'model.bcc', 'model.subject', 'model.body', 'model.callback', function () {
      var m = this.get("model");
      return [m.get("from"), m.get("to"), m.get("cc"), m.get("bcc"), m.get("subject"), m.get("body"), m.get("callback")].join(" ");
    }),

    actions: {
      goToStep2: function goToStep2() {
        this.set('model.type', this.get('type'));
        this.set('model.integration.type', this.get('provider'));
        this.transitionToRoute('new.step-2');
        return false;
      },
      goToStep3: function goToStep3(btn) {
        this.send('loading');
        this.set('loading', true);
        var $btn = btn.attr('disabled', 'disabled');
        var model = this.get('model');
        var that = this;

        model.save().then(function (obj) {
          that.set('model', obj);
          that.transitionToRoute('new.step-3');
        }, function () {
          //that.send('error', response);
        }).finally(function () {
          $btn.removeAttr('disabled');
          that.send('finished');
          that.set('loading', false);
        });
        return false;
      },
      toggleCC: function toggleCC() {
        this.toggleProperty('isShowingCC');
        return false;
      },
      toggleBCC: function toggleBCC() {
        this.toggleProperty('isShowingBCC');
        return false;
      }
    }
  });
});