define('messenger-webclient/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.get('store').createRecord('hook', {
        integration: { type: '' }
      });
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);

      controller.set('loading', false);
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }

  });
});