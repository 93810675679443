define('messenger-webclient/serializers/log', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    keyForAttribute: function keyForAttribute(key) {
      if (key === 'dataToParse') {
        return 'data';
      } else {
        return key;
      }
    }
  });
});