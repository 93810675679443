define("messenger-webclient/components/payload-help", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    stringToObj: function stringToObj(path, value, obj) {
      var parts = path.split(".");
      var last = parts.pop();
      var part = void 0;
      while (part = parts.shift()) {
        if (_typeof(obj[part]) !== "object") {
          obj[part] = {};
        }
        obj = obj[part]; // update "pointer"
      }
      obj[last] = value;
    },
    getContent: function getContent(root, regex, value, removeArray) {
      var content = value.match(regex);
      if (content) {
        var result = content.map(function (val) {
          removeArray.forEach(function (remove) {
            val = val.replace(remove, '');
          });
          return val;
        });
        for (var i = 0; i < result.length; i++) {
          if (result[i]) {
            this.stringToObj(result[i], "your content here", root);
          }
        }
      }
      return root;
    },
    parseVariables: function parseVariables(root, value) {
      return this.getContent(root, /{{([a-zA-Z_$][a-zA-Z._$0-9]+?)}}/g, value, ['{{', '}}']);
    },
    parseEach: function parseEach(root, value) {
      var eachs = value.match(/{{#each\s*([a-zA-Z_$][a-zA-Z._$0-9]+?)\s*}}/g);
      var that = this;

      eachs.forEach(function (r) {
        r = r.replace('{{#each ', '').replace('}}', '').trim();
        var obj = {};
        obj = that.parseVariables(obj, value);
        root[r] = [obj];
      });

      return root;
    },
    isEmptyObject: function isEmptyObject(obj) {
      return !Object.keys(obj).length;
    },


    htmlChanged: Ember.on('init', Ember.observer('html', function () {
      var root = {};

      var value = this.get("html");
      this.set("json", "");
      if (value) {

        var content = value.match(/{{#each\s*([a-zA-Z_$][a-zA-Z._$0-9]+?)\s*}}([\s\S]*?){{\/each}}/g);
        if (content) {
          var that = this;
          content.forEach(function (val) {
            root = that.parseEach(root, val);
            value = value.replace(val, '');
          });
        }

        root = this.parseVariables(root, value);

        if (!this.isEmptyObject(root)) {
          this.set('json', JSON.stringify(root, null, 2));
        }
      }
    }))
  });
});