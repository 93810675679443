define('messenger-webclient/transforms/unix-date', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return _moment.default.unix(serialized).format("YYYY-MM-DD HH:mm:ss");
      }
      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        return (0, _moment.default)(deserialized).format("X");
      }
      return deserialized;
    }
  });
});